import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import servicesStyles from "../styles/pages/services.module.css"

import service1 from "../images/service-1.png"
import service2 from "../images/service-2.png"
import service3 from "../images/service-3.png"
import service4 from "../images/service-4.png"
import servicePic1 from "../images/service-pic-1.jpg"
import servicePic2 from "../images/service-pic-2.jpg"
import servicePic3 from "../images/service-pic-3.jpg"
import servicePic4 from "../images/service-pic-4.jpg"
import servicePic5 from "../images/service-pic-5.jpg"
import servicePic6 from "../images/service-pic-6.jpg"
import servicePic7 from "../images/service-pic-7.png"
import servicePic8 from "../images/service-pic-8.jpg"
import servicePic9 from "../images/service-pic-9.png"
import servicePic10 from "../images/service-pic-10.jpg"
import servicePic11 from "../images/service-pic-11.jpg"
import servicePic12 from "../images/service-pic-12.png"
import servicePic13 from "../images/service-pic-13.jpg"
import servicePic14 from "../images/service-pic-14.jpg"
import servicePic15 from "../images/service-pic-15.jpg"
import servicePic16 from "../images/service-pic-16.png"

const ServicesPage = () => (
    <Layout location="services">
      <SEO title="Services" />
      <div className={ servicesStyles.services }>
        <h1>Services</h1>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imageRow }>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic1 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic2 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic3 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic4 }/></div>
          </div>
          <div className={ servicesStyles.serviceText}>
            <div className={ servicesStyles.serviceTitle }>
              <img src={ service1 } />
              <h3>Non-traditional Advertising</h3>
            </div>
            <div className={ servicesStyles.description }>
              <h5>Committed Service</h5>
              <p>We are committed to giving you the service that you want and the results that you deserve at the end of the day. We offer competitive rates at various packages done quickly and professionally.</p>
            </div>
          </div>
        </div>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imageRow }>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic5 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic6 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic7 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic8 }/></div>
          </div>
          <div className={ servicesStyles.serviceText }>
            <div className={ servicesStyles.serviceTitle }>
              <img src={ service2 } />
              <h3>Operational Activities</h3>
            </div>
            <div className={ servicesStyles.description }>
              <p><strong>T.E.A.M.P.L.A.Y.</strong> can provide trained field coordinators for various vacancies, namely: merchandisers, promodisers, push girls, field auditors, field collectors, bundlers, house-to-house researchers, area coordinators, service crews, encoders, merchandise installers, models, and others.</p>
              <p>We specialize in promotional activations - field promotions with activities ranging from single-detached intercepts, pocket events to large scale launches and customer building loyalty programs. We also specialize in non-traditional advertising, specifically: guerilla marketing, commando promotions, alternative media, or any other conceivable way of reaching your target market.</p>
            </div>
          </div>
        </div>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imageRow }>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic9 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic10 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic11 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic12 }/></div>
          </div>
          <div className={ servicesStyles.serviceText }>
            <div className={ servicesStyles.serviceTitle }>
              <img src={ service3 } />
              <h3>Promotions</h3>
            </div>
            <div className={ servicesStyles.description }>
              <section>
                <h5>Trained Team</h5>
                <p>We can provide you the best workforce and send them to locations right away. Members of the team are skilled and trained in field situations may they be city-wide, regional, or nationwide.</p>
              </section>
              <section>
                <h5>Hassle-free Deployment</h5>
                <p>We can assure you a smooth and fast deployment. With over 20 years of deployment experience, we stress the importance of real-time responsiveness/response, thereby helping you save time and money.</p>
              </section>
            </div>
          </div>
        </div>
        <div className={ servicesStyles.service }>
          <div className={ servicesStyles.imageRow }>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic13 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic14 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic15 }/></div>
            <div className={ servicesStyles.imgWrap }><img src={ servicePic16 }/></div>
          </div>
          <div className={ servicesStyles.serviceText }>
            <div className={ servicesStyles.serviceTitle }>
              <img src={ service4 } />
              <h3>Other Related Services</h3>
            </div>
            <div className={ servicesStyles.description }>
              <p>This includes the following: <span>promo booths, kiosks, stalls design & production, promotional novelties, and giveaways.</span></p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
)

export default ServicesPage